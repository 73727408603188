import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Bio from "../components/bio"

const Review = () => {
  return (
    <Layout
      prev={{ name: "네오-믹스", link: "/neomix" }}
      next={{ name: "큐레이터 토크", link: "/talk" }}
      title={`네오-\u200B트라이브\u200B2020: \u200B그래도 다시 만나고 싶을 때
      성정은`}
    >
      <SEO title="평론의 글" />
      <p>
        전시공간인 웹사이트에 접속하자마자 전시를 전염병과 비대면 시대 자체로
        은유하는 방식을 통해 작동시키고 있다는 것을 눈치챘습니다. 바이러스의
        이미지를 연상시키는 웹 공간의 시각적 구성요소들이 자조적으로 느껴져, 그
        반동감으로 ‘신-세대(Neo-Tribe)’들의 이목을 끄는 동시에 전시의 골격은 이
        혼란한 상황에서도 힘내보자는 코로나 시대정신을 아우르고 있다는 점이 사뭇
        상쾌하게 느껴집니다. 사회적 거리 두기에 대하여 말하고 있는 레터링
        작품들은 직접 대면해 쓰는 음성적 언어보다 비대면 상황에서의 다양한
        문자∙시각적 언어에 더 긴밀히 반응해야 할 지 모를 우리의 미래를 예언하는
        걸까요? 발화자의 감정과 전달하고자 하는 말의 어조를 다채로운 방식으로
        표현한 서체와 레터링 작품들을 보니 왠지 시끌벅적 함께 얘기하던 제
        친구들이 보고 싶어지는 것 같기도 합니다. 17인의 작품 일부 구절을
        랜덤으로 조합해 새로운 메시지를 만들어내는 ‘믹스!’ 기능은 비대면
        상황에서의 유희적 커뮤니케이션 방식을 즉각적으로 보여줍니다. ‘그럼에도
        불구하고 집콕중’, ‘모여봐요 방구석’ 등, 허를 찌르는 조합들은 마치 무작위
        한 상황에서 나름대로 변이하는 바이러스 같기도 하고요. 마지막으로, 글꼴에
        관한 전시인 만큼 웹사이트에 사용한 패밀리 폰트를 볼까요?
        ‘산돌그레타산스’는 현재 베타 테스트 중인 서체입니다. 기존의 산세리프
        서체보다 의외적이고 새로운 미감은 코로나 시대 특유의 ‘언제든 상황은 변할
        수 있다’는 변동성이 높은 분위기를 시사하는 것처럼 보이기에 아주 탁월하지
        않나요? 베타 테스트를 거쳐 곧 출시를 앞둔 이 서체처럼 코로나 바이러스의
        백신도 곧 나타나준다면 정말 좋을 텐데 말이죠!
      </p>
      <br />
      <p>
        당분간 인간에게 예술의 향유는 사치라는 듯 전 세계의 많은 박물관,
        미술관들이 문을 걸어 잠가 적막해진 화이트 큐브지만, 지독하게 변이하는
        바이러스가 존재하더라도 전시 공간 또한, 상황과 처지에 맞게 변하는
        유동적인 대응이 가능하다는 것을 함께 목격하고 있습니다. 뉴욕
        메트로폴리탄미술관은 게임 «모여봐요, 동물의 숲»을 플레이하며 소장품을
        감상할 수 있도록 QR코드를 공유했고, 일본의 동영상 공유 사이트
        니코니코동화는 모바일 앱을 통해 휴관 중인 미술관의 전시를 공개했으며,
        한국의 부산시립미술관, 일민미술관은 온라인 VR 관람 서비스를
        개방했습니다. 이도타입은 «네오-트라이브2020: 사회가 잠시 멀어졌을 때»를
        성공적으로 개최했죠!
      </p>
      <br />
      <Bio
        name={"성정은"}
        content={
          "성정은은 여성주의, 팝-서브 컬처 이슈에 관한 디자인 기획과 전시에 관심이 많다. 서울을 기반으로 활동하는 DJ와 디자이너가 협업하는 공연 «Edit Point»(2017, PLATFORM-L), «Open Recent Graphic Design 2018»(2018, 공간41), «Latency: 구간반복»(2019, 공간 사일삼) 등에 참여했고 «메타후조»(2016), «여명기»(2020) 등을 디자인했다."
        }
      />
    </Layout>
  )
}

export default Review
